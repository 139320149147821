import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { EventBusService } from './services/event-bus.service';
import { StorageService } from './services/storage.service';
import { EventLogout } from './shared/interfaces/events';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'CMS';
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username?: string;

  eventBusSub?: Subscription;

  constructor(
    private storageService: StorageService,
    private eventBusService: EventBusService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.eventBusSub = this.eventBusService.on(EventLogout.EVENT_LOGOUT, (message: string) => {
      this.logout(message);
    })
  }

  logout(message: string): void {
    this.storageService.clean();
    this.authService.logout(message);
    this.router.navigateByUrl('login')
  }
}
