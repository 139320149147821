import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";

import { EventBusService } from "../services/event-bus.service";
import { StorageService } from "../services/storage.service";
import { EventLogout } from "../shared/interfaces/events";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private storageService: StorageService,
    private eventBusService: EventBusService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('/login')) {
      const token = this.storageService.getToken().token;
      req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if ( error instanceof HttpErrorResponse && 
          !req.url.includes('user/login') 
          && error.status === 401 ) {

          return this.handleError(req, next);
        }

        return throwError(() => error);
      })
    );
  }

  private handleError(request: HttpRequest<any>, next: HttpHandler) {
    this.eventBusService.emit(new EventLogout('Sua sessão expirou'));
    return next.handle(request);
  }
}

export const httpInterceptorProviders = { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true };