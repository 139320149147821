import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { StorageService } from '../services/storage.service';

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  const storage = inject(StorageService);
  const isLoggedIn = storage.isLoggedIn();
  
  return isLoggedIn ? true : router.parseUrl('/login');
};
